// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EAf6UbN5cxms8XT9pFed {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vxVKQ3VRXI8lDLb2II4l {
  margin-right: 10px;
}

.MrBXeLv7jtHyVUbB4CAP {
  margin-bottom: 20px;
}

.KYtqWurv0u644quCwLKw {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--title-marginBottom);
}

.yiFxwPSCLfrA2GBjXbt5 {
  display: flex;
  align-items: center;
}

.t0LO3CJNM9x2dzjGYcOb .EAf6UbN5cxms8XT9pFed {
  margin-bottom: 0;
}

.PAw2AcHU0UjlocjQ_9Xc {
  display: flex;
  justify-content: flex-end;
}

.jsYg75WjhnV0h8NzUqfT {
  word-wrap: break-word;
  word-break: break-word;
}

.Yb9Ye8kXT0ymYaafZXxM {
  display: flex;
  justify-content: space-between;
}

.EoaflKhoPhZ0KTjaFTwi {
  margin-right: 8px;
  color: var(--warning-text-color);
}

.NFb6lmjDhaU8Of7RxSw6 {
  display: inline-block;
  white-space: break-spaces;
  line-height: 20px;
  color: var(--error-text-color);
}

.NFb6lmjDhaU8Of7RxSw6 svg {
  vertical-align: middle;
}

.B90H1gGdKM6i1WEibmjC {
  color: var(--warning-text-color);
}

.U3pzKsJXWhLu1QJPqW28 {
  color: var(--success-text-color);
}
`, "",{"version":3,"sources":["webpack://./pages/users/Users.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,wCAAwC;AAC1C;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;EACrB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,iBAAiB;EACjB,gCAAgC;AAClC;;AAEA;EACE,qBAAqB;EACrB,yBAAyB;EACzB,iBAAiB;EACjB,8BAA8B;AAChC;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".users-title {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.user-avatar {\n  margin-right: 10px;\n}\n\n.users-filters {\n  margin-bottom: 20px;\n}\n\n.users-header {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: var(--title-marginBottom);\n}\n\n.users-header-left {\n  display: flex;\n  align-items: center;\n}\n\n.root .users-title {\n  margin-bottom: 0;\n}\n\n.users-footer {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.desc {\n  word-wrap: break-word;\n  word-break: break-word;\n}\n\n.user-filters-container {\n  display: flex;\n  justify-content: space-between;\n}\n\n.warning-message-icon {\n  margin-right: 8px;\n  color: var(--warning-text-color);\n}\n\n.error-icon {\n  display: inline-block;\n  white-space: break-spaces;\n  line-height: 20px;\n  color: var(--error-text-color);\n}\n\n.error-icon svg {\n  vertical-align: middle;\n}\n\n.warning-message {\n  color: var(--warning-text-color);\n}\n\n.success-message {\n  color: var(--success-text-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"users-title": `EAf6UbN5cxms8XT9pFed`,
	"user-avatar": `vxVKQ3VRXI8lDLb2II4l`,
	"users-filters": `MrBXeLv7jtHyVUbB4CAP`,
	"users-header": `KYtqWurv0u644quCwLKw`,
	"users-header-left": `yiFxwPSCLfrA2GBjXbt5`,
	"root": `t0LO3CJNM9x2dzjGYcOb`,
	"users-footer": `PAw2AcHU0UjlocjQ_9Xc`,
	"desc": `jsYg75WjhnV0h8NzUqfT`,
	"user-filters-container": `Yb9Ye8kXT0ymYaafZXxM`,
	"warning-message-icon": `EoaflKhoPhZ0KTjaFTwi`,
	"error-icon": `NFb6lmjDhaU8Of7RxSw6`,
	"warning-message": `B90H1gGdKM6i1WEibmjC`,
	"success-message": `U3pzKsJXWhLu1QJPqW28`
};
export default ___CSS_LOADER_EXPORT___;
