// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ov44mKjqGol5o4avJFfk {
  color: var(--primary-text-link);
}

.lsSzmt2PCTzK8MIRd_Ap {
  display: block;
  text-align: left;
  margin-bottom: 12px;
}

.wnJcVsHKozTveH9G7AiX {
  text-decoration: underline;
}

.nztqWoeJWlUJM90Ddzrz {
  color: var(--primary-text-link);
}

.dpkX4MjnrTHa3Ynkt6Vp {
  margin-top: 24px;
}

.IukrLTGO7UxWC0g44SrG {
  width: 100%;
}

.i79RCkLDseE9UcTTWQVY {
  margin-top: 8px;
  width: 100%;
  display: inline-block;
}

.pt_Kuh73aSatP4eb4Z86 {
  margin-left: 48px;
  margin-right: 48px;
}
`, "",{"version":3,"sources":["webpack://./containers/TelegramIntegrationButton/TelegramIntegrationButton.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;EACf,WAAW;EACX,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".telegram-bot {\n  color: var(--primary-text-link);\n}\n\n.telegram-instruction-container {\n  display: block;\n  text-align: left;\n  margin-bottom: 12px;\n}\n\n.verification-code {\n  text-decoration: underline;\n}\n\n.copy-icon {\n  color: var(--primary-text-link);\n}\n\n.telegram-instruction-cancel {\n  margin-top: 24px;\n}\n\n.telegram-block {\n  width: 100%;\n}\n\n.field-command {\n  margin-top: 8px;\n  width: 100%;\n  display: inline-block;\n}\n\n.infoblock-text {\n  margin-left: 48px;\n  margin-right: 48px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"telegram-bot": `ov44mKjqGol5o4avJFfk`,
	"telegram-instruction-container": `lsSzmt2PCTzK8MIRd_Ap`,
	"verification-code": `wnJcVsHKozTveH9G7AiX`,
	"copy-icon": `nztqWoeJWlUJM90Ddzrz`,
	"telegram-instruction-cancel": `dpkX4MjnrTHa3Ynkt6Vp`,
	"telegram-block": `IukrLTGO7UxWC0g44SrG`,
	"field-command": `i79RCkLDseE9UcTTWQVY`,
	"infoblock-text": `pt_Kuh73aSatP4eb4Z86`
};
export default ___CSS_LOADER_EXPORT___;
