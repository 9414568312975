// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ny9Co0seP7orJsanmdb4{width:100%;display:flex;flex-direction:row;flex-wrap:nowrap;overflow:hidden;gap:12px}.JEJk1fW7BOuHI3o9Pws1{display:flex;white-space:nowrap;flex-direction:row;gap:4px}.vgCcRBuGjFEqgmOhRpYy{flex-grow:1;overflow:hidden}.j7P04n6dCcekR5Q5Sy3r{overflow:hidden;max-width:calc(100% - 48px);text-overflow:ellipsis}.f42AEBpCBsYNaeKTqkA9{margin-right:4px}.SJ2sDBqdR_qaO3NTkiBQ{display:flex;flex-direction:row;flex-wrap:wrap;gap:8px}.ssM87eiLJ_HmkcdzZcjK{display:flex;flex-direction:row}`, "",{"version":3,"sources":["webpack://./containers/IntegrationContainers/CollapsedIntegrationRouteDisplay/CollapsedIntegrationRouteDisplay.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,eAAA,CACA,QAAA,CAEA,sBACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,OAAA,CAGF,sBACE,WAAA,CACA,eAAA,CAGF,sBACE,eAAA,CACA,2BAAA,CACA,sBAAA,CAIJ,sBACE,gBAAA,CAIA,sBACE,YAAA,CACA,kBAAA,CACA,cAAA,CACA,OAAA,CAGF,sBACE,YAAA,CACA,kBAAA","sourcesContent":[".heading-container {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  overflow: hidden;\n  gap: 12px;\n\n  &__item {\n    display: flex;\n    white-space: nowrap;\n    flex-direction: row;\n    gap: 4px;\n  }\n\n  &__item--large {\n    flex-grow: 1;\n    overflow: hidden;\n  }\n\n  &__text {\n    overflow: hidden;\n    max-width: calc(100% - 48px);\n    text-overflow: ellipsis;\n  }\n}\n\n.icon {\n  margin-right: 4px;\n}\n\n.collapsedRoute {\n  &__container {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    gap: 8px;\n  }\n\n  &__item {\n    display: flex;\n    flex-direction: row;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading-container": `Ny9Co0seP7orJsanmdb4`,
	"heading-container__item": `JEJk1fW7BOuHI3o9Pws1`,
	"heading-container__item--large": `vgCcRBuGjFEqgmOhRpYy`,
	"heading-container__text": `j7P04n6dCcekR5Q5Sy3r`,
	"icon": `f42AEBpCBsYNaeKTqkA9`,
	"collapsedRoute__container": `SJ2sDBqdR_qaO3NTkiBQ`,
	"collapsedRoute__item": `ssM87eiLJ_HmkcdzZcjK`
};
export default ___CSS_LOADER_EXPORT___;
