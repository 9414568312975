// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NDKBNbxhLY0uIdo2qYwe{display:flex;align-items:center;width:100%;padding-top:12px}.RfbWJrjX643S2Jho9V4h{display:flex;align-items:baseline}.doz5QPmTJp2FWZD3DrDw{position:absolute;right:0;top:-48px}`, "",{"version":3,"sources":["webpack://./pages/outgoing_webhooks/OutgoingWebhooks.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,UAAA,CACA,gBAAA,CAGF,sBACE,YAAA,CACA,oBAAA,CAGF,sBACE,iBAAA,CACA,OAAA,CACA,SAAA","sourcesContent":[".header {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  padding-top: 12px;\n}\n\n.header__title {\n  display: flex;\n  align-items: baseline;\n}\n\n.newWebhookButton {\n  position: absolute;\n  right: 0;\n  top: -48px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `NDKBNbxhLY0uIdo2qYwe`,
	"header__title": `RfbWJrjX643S2Jho9V4h`,
	"newWebhookButton": `doz5QPmTJp2FWZD3DrDw`
};
export default ___CSS_LOADER_EXPORT___;
