// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eBU1UFlh3lJJhRZ3So_g{overflow-x:auto;white-space:nowrap}`, "",{"version":3,"sources":["webpack://./navbar/LegacyNavTabsBar.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,kBAAA","sourcesContent":[".root {\n  overflow-x: auto;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `eBU1UFlh3lJJhRZ3So_g`
};
export default ___CSS_LOADER_EXPORT___;
