// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Zr4xoqxOQtvfPbZn0HS9 {
  color: var(--primary-text-link);
}

.ZueOZQmtTbaW3rCe1EHq {
  display: block;
  text-align: left;
  margin-bottom: 12px;
}

.wlev2SCzgmyLkNWoFyZJ {
  text-decoration: underline;
}

.ZG6_hb_dWa32r0YbDBKH {
  color: var(--primary-text-link);
}

.e2vn4X8sO3PTPCAxACef {
  margin-top: 24px;
}

.JC9XlcEVcNDTPmV2pwQD {
  min-width: 800px;
}
`, "",{"version":3,"sources":["webpack://./containers/MSTeamsIntegrationButton/MSTeamsIntegrationButton.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".msteams-bot {\n  color: var(--primary-text-link);\n}\n\n.msteams-instruction-container {\n  display: block;\n  text-align: left;\n  margin-bottom: 12px;\n}\n\n.verification-code {\n  text-decoration: underline;\n}\n\n.copy-icon {\n  color: var(--primary-text-link);\n}\n\n.msteams-instruction-cancel {\n  margin-top: 24px;\n}\n\n.msTeams-modal {\n  min-width: 800px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"msteams-bot": `Zr4xoqxOQtvfPbZn0HS9`,
	"msteams-instruction-container": `ZueOZQmtTbaW3rCe1EHq`,
	"verification-code": `wlev2SCzgmyLkNWoFyZJ`,
	"copy-icon": `ZG6_hb_dWa32r0YbDBKH`,
	"msteams-instruction-cancel": `e2vn4X8sO3PTPCAxACef`,
	"msTeams-modal": `JC9XlcEVcNDTPmV2pwQD`
};
export default ___CSS_LOADER_EXPORT___;
