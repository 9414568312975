// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wcTQFUmcRkI_9qGyU7ON {
  display: block;
}

.X0prPR38BWxTj0waD2NA {
  color: var(--success-text-color);
}
`, "",{"version":3,"sources":["webpack://./containers/EscalationChainCard/EscalationChainCard.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".root {\n  display: block;\n}\n\n.icon {\n  color: var(--success-text-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `wcTQFUmcRkI_9qGyU7ON`,
	"icon": `X0prPR38BWxTj0waD2NA`
};
export default ___CSS_LOADER_EXPORT___;
