// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.C8BiwZPUejqSfRqFyCQb {
  --rotations-border: var(--border-weak);
  --rotations-background: var(--background-secondary);
}

.ZcFJgvIifgjKargBtyCN {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  column-gap: 8px;
  row-gap: 8px;
  min-width: 250px;
  align-items: center;
}

.N3nQcAcIls98Jw9XyTzi {
  position: sticky; /* TODO check */
  width: 100%;
}

.Ufl1makMfF9A2tEg7Hst {
  width: 736px;
}

.TsviDhBP_otxanR2ukKC {
  width: 100%;
}

.v2C6cAtELzjUX5qrB7VO {
  width: 100%;
}

.N1tV87CJOYvZ8IIGBc3L {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.uulMtATF7zPrtzAOeBS9 {
  margin: 50px auto;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./pages/schedule/Schedule.module.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,mDAAmD;AACrD;;AAEA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB,EAAE,eAAe;EACjC,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".root {\n  --rotations-border: var(--border-weak);\n  --rotations-background: var(--background-secondary);\n}\n\n.title {\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: row;\n  column-gap: 8px;\n  row-gap: 8px;\n  min-width: 250px;\n  align-items: center;\n}\n\n.header {\n  position: sticky; /* TODO check */\n  width: 100%;\n}\n\n.desc {\n  width: 736px;\n}\n\n.users-timezones {\n  width: 100%;\n}\n\n.controls {\n  width: 100%;\n}\n\n.rotations {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  position: relative;\n  width: 100%;\n}\n\n.not-found {\n  margin: 50px auto;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `C8BiwZPUejqSfRqFyCQb`,
	"title": `ZcFJgvIifgjKargBtyCN`,
	"header": `N3nQcAcIls98Jw9XyTzi`,
	"desc": `Ufl1makMfF9A2tEg7Hst`,
	"users-timezones": `TsviDhBP_otxanR2ukKC`,
	"controls": `v2C6cAtELzjUX5qrB7VO`,
	"rotations": `N1tV87CJOYvZ8IIGBc3L`,
	"not-found": `uulMtATF7zPrtzAOeBS9`
};
export default ___CSS_LOADER_EXPORT___;
