// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Goj6Fnossnkos9z2aUTg {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.lfUhdeqSgBLwAacjmOZV {
  display: flex;
  gap: 10px;
  padding: 10px;
  border: var(--border);
  border-radius: 2px;
  flex-wrap: wrap;
}

.t7puoZgp9lSZbC4TFfjp {
  display: flex;
  align-items: center;
  gap: 3px;
}

.Goj6Fnossnkos9z2aUTg .AG_QQYOUKKBoBquzVQMH {
  width: 250px;
}

.Goj6Fnossnkos9z2aUTg .LAScFUoa3G0Dqe3eKZy5 {
  min-width: 250px;
  width: fit-content;
}
`, "",{"version":3,"sources":["webpack://./containers/RemoteFilters/RemoteFilters.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,SAAS;EACT,aAAa;EACb,qBAAqB;EACrB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.filters {\n  display: flex;\n  gap: 10px;\n  padding: 10px;\n  border: var(--border);\n  border-radius: 2px;\n  flex-wrap: wrap;\n}\n\n.filter {\n  display: flex;\n  align-items: center;\n  gap: 3px;\n}\n\n.root .filter-options {\n  width: 250px;\n}\n\n.root .filter-select {\n  min-width: 250px;\n  width: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Goj6Fnossnkos9z2aUTg`,
	"filters": `lfUhdeqSgBLwAacjmOZV`,
	"filter": `t7puoZgp9lSZbC4TFfjp`,
	"filter-options": `AG_QQYOUKKBoBquzVQMH`,
	"filter-select": `LAScFUoa3G0Dqe3eKZy5`
};
export default ___CSS_LOADER_EXPORT___;
