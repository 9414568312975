// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WjyavLi6nOi4vgidsDD6{border:var(--border-weak)}.Pxebxh3wy6C_pX7V0xu4{width:728px}.Ke7BscXJo__XzAUeR2Cz{width:765px}.aZLLbIM9ob8zwQc8ZuDh{display:flex;flex-direction:column;width:200px;border-radius:2px}.kmdjI8mQDM5z_948kRg6{padding:8px;display:flex;align-items:center;flex-direction:row;flex-shrink:0;white-space:nowrap;border-left:2px solid rgba(0,0,0,0);cursor:pointer;min-width:84px;display:flex;gap:8px;flex-direction:row}.kmdjI8mQDM5z_948kRg6:hover{background:var(--cards-background)}.ifAeEIqwWhITlu8xLVcg{margin-bottom:8px}.TeVetqw2yEt1Zpn5gXBk{padding-top:6px}.kQWGybKqyI5DwyEUjc0f{min-height:40px}`, "",{"version":3,"sources":["webpack://./containers/IntegrationContainers/ExpandedIntegrationRouteDisplay/ExpandedIntegrationRouteDisplay.module.scss"],"names":[],"mappings":"AAAA,sBACE,yBAAA,CAEA,sBACE,WAAA,CAIJ,sBACE,WAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,iBAAA,CAGF,sBACE,WAAA,CACA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,aAAA,CACA,kBAAA,CACA,mCAAA,CACA,cAAA,CACA,cAAA,CACA,YAAA,CACA,OAAA,CACA,kBAAA,CAEA,4BACE,kCAAA,CAIJ,sBACE,iBAAA,CAGF,sBACE,eAAA,CAGF,sBACE,eAAA","sourcesContent":[".input {\n  border: var(--border-weak);\n\n  &--align {\n    width: 728px;\n  }\n}\n\n.routing-alert {\n  width: 765px;\n}\n\n.integrations-actionsList {\n  display: flex;\n  flex-direction: column;\n  width: 200px;\n  border-radius: 2px;\n}\n\n.integrations-actionItem {\n  padding: 8px;\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n  flex-shrink: 0;\n  white-space: nowrap;\n  border-left: 2px solid transparent;\n  cursor: pointer;\n  min-width: 84px;\n  display: flex;\n  gap: 8px;\n  flex-direction: row;\n\n  &:hover {\n    background: var(--cards-background);\n  }\n}\n\n.routing-template-container {\n  margin-bottom: 8px;\n}\n\n.adjust-element-padding {\n  padding-top: 6px;\n}\n\n.default-route-view {\n  min-height: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `WjyavLi6nOi4vgidsDD6`,
	"input--align": `Pxebxh3wy6C_pX7V0xu4`,
	"routing-alert": `Ke7BscXJo__XzAUeR2Cz`,
	"integrations-actionsList": `aZLLbIM9ob8zwQc8ZuDh`,
	"integrations-actionItem": `kmdjI8mQDM5z_948kRg6`,
	"routing-template-container": `ifAeEIqwWhITlu8xLVcg`,
	"adjust-element-padding": `TeVetqw2yEt1Zpn5gXBk`,
	"default-route-view": `kQWGybKqyI5DwyEUjc0f`
};
export default ___CSS_LOADER_EXPORT___;
