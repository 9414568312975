// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LdABsxj7fBRV0sRafWyu {
  margin-bottom: 20px;
}

.TZ2FWXSYo6osCorLn1e8 {
  margin: 10px 20px;
}

.jyDKuyH3zW4F_OhRSpfE {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border: var(--border);
  border-radius: 2px;
}

.pptDWdm6z70cgkZ610_G {
  margin: 16px;
  width: calc(100% - 32px);
}

.XODxnOuAyb4JGyrWtKCs {
  width: 300px;
  flex-shrink: 0;
  border-right: var(--border);
}

.wUzWWJfu9T_gu5sB1sBa {
  overflow: auto;
  max-height: 70vh;
}

.uP4ldAvmeGJP7JCg6VRb {
  margin: 16px;
  flex-grow: 1;
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.jVtJv3VIsOC9m_ddkYEX {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.WN_lL9C0zXNFna2QJd3G {
  margin: 0;
  list-style-type: none;
}
`, "",{"version":3,"sources":["webpack://./pages/escalation-chains/EscalationChains.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,wBAAwB;AAC1B;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,2BAA2B;AAC7B;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,SAAS;EACT,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,qBAAqB;AACvB","sourcesContent":[".filters {\n  margin-bottom: 20px;\n}\n\n.loading {\n  margin: 10px 20px;\n}\n\n.escalations {\n  width: 100%;\n  display: flex;\n  align-items: flex-start;\n  border: var(--border);\n  border-radius: 2px;\n}\n\n.new-escalation-chain {\n  margin: 16px;\n  width: calc(100% - 32px);\n}\n\n.left-column {\n  width: 300px;\n  flex-shrink: 0;\n  border-right: var(--border);\n}\n\n.escalations-list {\n  overflow: auto;\n  max-height: 70vh;\n}\n\n.escalation {\n  margin: 16px;\n  flex-grow: 1;\n  display: flex;\n  gap: 10px;\n  flex-direction: column;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.list {\n  margin: 0;\n  list-style-type: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filters": `LdABsxj7fBRV0sRafWyu`,
	"loading": `TZ2FWXSYo6osCorLn1e8`,
	"escalations": `jyDKuyH3zW4F_OhRSpfE`,
	"new-escalation-chain": `pptDWdm6z70cgkZ610_G`,
	"left-column": `XODxnOuAyb4JGyrWtKCs`,
	"escalations-list": `wUzWWJfu9T_gu5sB1sBa`,
	"escalation": `uP4ldAvmeGJP7JCg6VRb`,
	"header": `jVtJv3VIsOC9m_ddkYEX`,
	"list": `WN_lL9C0zXNFna2QJd3G`
};
export default ___CSS_LOADER_EXPORT___;
