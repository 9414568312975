// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ooibJNolYY9E9ctVwBzr {
  display: block;
}

.X5GzsxTNVuczp5WKeYH2 {
  margin: 0 0 0 16px;
}

.FTedBozUSBqqEV_lAAWL {
  margin: 4px;
}

.g56WWI12WfqjXmUmxmil {
  padding-top: 16px;
}

.hAKBj5WOyXPpxrgo8k4C {
  display: flex;
  flex-wrap: nowrap;
  gap: 4px;
}

.MrzxitlWbL6mO_bCFlGK {
  flex-grow: 1;
}

/* TODO: figure out why this is not picked */
.KJWAoKDANsEQTkNoypBW .To6XBtKu7QAWMTOKbotS.eykohTe_tPsEcz30o23o {
  display: none !important;
}

.Hxuw_XWStTX5gM8UCN6K {
  height: calc(100vh - 530px);
  min-height: 200px;
}

.GwScEC2bZ4NH29j4RPLr {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  overflow: auto;
  scroll-snap-type: y mandatory;
  width: 100%;
}

.hW5r81PrLkwJGZOwKbc2 {
  width: 100%;
  height: 106px;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  position: relative;
  gap: 20px;
}

.HH0ylQgF_h2BekV6jQsq {
  width: 100%;
  margin-bottom: 24px;
}
`, "",{"version":3,"sources":["webpack://./containers/OutgoingWebhookForm/OutgoingWebhookForm.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,QAAQ;AACV;;AAEA;EACE,YAAY;AACd;;AAEA,4CAA4C;AAC5C;EACE,wBAAwB;AAC1B;;AAEA;EACE,2BAA2B;EAC3B,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,SAAS;EACT,cAAc;EACd,6BAA6B;EAC7B,WAAW;AACb;;AAEA;EACE,WAAW;EACX,aAAa;EACb,wBAAwB;EACxB,wBAAwB;EACxB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,2BAA2B;EAC3B,eAAe;EACf,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB","sourcesContent":[".root {\n  display: block;\n}\n\n.title {\n  margin: 0 0 0 16px;\n}\n\n.content {\n  margin: 4px;\n}\n\n.tabsWrapper {\n  padding-top: 16px;\n}\n\n.form-row {\n  display: flex;\n  flex-wrap: nowrap;\n  gap: 4px;\n}\n\n.form-field {\n  flex-grow: 1;\n}\n\n/* TODO: figure out why this is not picked */\n.webhooks__drawerContent .cursor.monaco-mouse-cursor-text {\n  display: none !important;\n}\n\n.sourceCodeRoot {\n  height: calc(100vh - 530px);\n  min-height: 200px;\n}\n\n.cards {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 24px;\n  overflow: auto;\n  scroll-snap-type: y mandatory;\n  width: 100%;\n}\n\n.card {\n  width: 100%;\n  height: 106px;\n  scroll-snap-align: start;\n  scroll-snap-stop: normal;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  cursor: pointer;\n  position: relative;\n  gap: 20px;\n}\n\n.search-integration {\n  width: 100%;\n  margin-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `ooibJNolYY9E9ctVwBzr`,
	"title": `X5GzsxTNVuczp5WKeYH2`,
	"content": `FTedBozUSBqqEV_lAAWL`,
	"tabsWrapper": `g56WWI12WfqjXmUmxmil`,
	"form-row": `hAKBj5WOyXPpxrgo8k4C`,
	"form-field": `MrzxitlWbL6mO_bCFlGK`,
	"webhooks__drawerContent": `KJWAoKDANsEQTkNoypBW`,
	"cursor": `To6XBtKu7QAWMTOKbotS`,
	"monaco-mouse-cursor-text": `eykohTe_tPsEcz30o23o`,
	"sourceCodeRoot": `Hxuw_XWStTX5gM8UCN6K`,
	"cards": `GwScEC2bZ4NH29j4RPLr`,
	"card": `hW5r81PrLkwJGZOwKbc2`,
	"search-integration": `HH0ylQgF_h2BekV6jQsq`
};
export default ___CSS_LOADER_EXPORT___;
