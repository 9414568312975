// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SU3YOIZNGwjo6D6caZYp {
  position: relative;
  margin: 20px 0;
}

.qYV9cCeNiOxEvlVcn6qB {
  position: relative;
  margin: 20px 0;

  --rotations-border: var(--border-weak);
  --rotations-background: var(--background-secondary);
}

.wRZ7YKJYxUGOk7Q5yKvR {
  margin-bottom: var(--title-marginBottom);
}

.NVJTrOWGZI7bI1pn3hsV .NZutD9pVFmYCmUHpDHm9 {
  padding-right: 10px;
}

.mMsM7sV41nigCwq1zXFD {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 4px;
  column-gap: 8px;
  width: 100%;
  margin-bottom: 20px;
}

.zu8XfT6KwpsEWimS0orf {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  gap: 8px;
}

.IwdUZnLYZhgdq8sf1Ces {
  margin-top: 5px;
}
`, "",{"version":3,"sources":["webpack://./pages/schedules/Schedules.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,cAAc;;EAEd,sCAAsC;EACtC,mDAAmD;AACrD;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,eAAe;EACf,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,QAAQ;AACV;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".schedule {\n  position: relative;\n  margin: 20px 0;\n}\n\n.schedule-personal {\n  position: relative;\n  margin: 20px 0;\n\n  --rotations-border: var(--border-weak);\n  --rotations-background: var(--background-secondary);\n}\n\n.title {\n  margin-bottom: var(--title-marginBottom);\n}\n\n.root .buttons {\n  padding-right: 10px;\n}\n\n.schedules__filters-container {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  row-gap: 4px;\n  column-gap: 8px;\n  width: 100%;\n  margin-bottom: 20px;\n}\n\n.schedules__actions {\n  display: flex;\n  justify-content: flex-end;\n  flex-grow: 1;\n  gap: 8px;\n}\n\n.button-back {\n  margin-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"schedule": `SU3YOIZNGwjo6D6caZYp`,
	"schedule-personal": `qYV9cCeNiOxEvlVcn6qB`,
	"title": `wRZ7YKJYxUGOk7Q5yKvR`,
	"root": `NVJTrOWGZI7bI1pn3hsV`,
	"buttons": `NZutD9pVFmYCmUHpDHm9`,
	"schedules__filters-container": `mMsM7sV41nigCwq1zXFD`,
	"schedules__actions": `zu8XfT6KwpsEWimS0orf`,
	"button-back": `IwdUZnLYZhgdq8sf1Ces`
};
export default ___CSS_LOADER_EXPORT___;
