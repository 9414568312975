// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oYArYu0JlM_OSXQ7dZI_{width:400px}.JVj4tuphCIWyMbXVcwaQ{display:flex}.yDq7jAfMfiTqFjY8cdzl{color:var(--primary-text-link)}.TRUeiqMdxOzCuRLnQict{margin-left:4px}`, "",{"version":3,"sources":["webpack://./containers/GrafanaTeamSelect/GrafanaTeamSelect.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CAGF,sBACE,YAAA,CAGF,sBACE,8BAAA,CAGF,sBACE,eAAA","sourcesContent":[".root {\n  width: 400px;\n}\n\n.teamSelectLabel {\n  display: flex;\n}\n\n.teamSelectLink {\n  color: var(--primary-text-link);\n}\n\n.teamSelectInfo {\n  margin-left: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `oYArYu0JlM_OSXQ7dZI_`,
	"teamSelectLabel": `JVj4tuphCIWyMbXVcwaQ`,
	"teamSelectLink": `yDq7jAfMfiTqFjY8cdzl`,
	"teamSelectInfo": `TRUeiqMdxOzCuRLnQict`
};
export default ___CSS_LOADER_EXPORT___;
